<template>
  <div class="games">
    <div class="topBanner">
      <div class="topBannerContent">
        <h2>GAMES</h2>
        <p>With its mission to Bring Happiness to All Players in mind, Good Show is committed to explore ensuring gaming
          experiences beyond all expectations.</p>
      </div>
    </div>
    <div class="game">
      <div class="gameContent">
        <div>
          <h2>COOKING LIFE</h2>
          <p>Are you into cooking games and designing games? Then welcome to Cooking Live!
            Enjoy the fun that cooking brings to your life</p>
          <!-- <a href="https://play.google.com/store/apps/details?id=com.co.sev.max&hl=en&gl=hk" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/google.png" class="google" alt=""></a> -->
        </div>
        <img class="gameLogo" src="@/assets/img/game1.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  components: {
  },
  computed: {
    ...Vuex.mapGetters([
      "bannerImg",
      "headerImg",
    ])
  },

}
</script>
<style lang="stylus">
.games {
  background-color: #f3f3f3;
  padding-top: .96rem;
  .topBanner {
    margin: 0 auto;
    background: url('~@/assets/img/gameBanner.png') center center no-repeat;
    background-size: 100%;
    height: 0;
    padding-bottom: 43.12%
    
  }
  & > div {
    font-family: gilroy;
    &:nth-child(even) {
      background-color: #fff;
    }
    h2 {
      font-weight: bold;
      font-size: .5rem;
      font-family: gotham;
    }
  }
  .topBanner {
    position: relative;
    display: flex;
    color: $Cf;
    h2 {
      font-size: .8rem;
    }
    img {
      height: auto;
      margin-right: 5%;
      margin-left: 4%;
    }
    .topBannerContent {
      position: absolute;
      top: 50%;
      right: 6%;
      width 26%;
      background: rgba(0, 0, 0, .6);
      padding: .7rem .8rem;
      transform: translateY(-50%);
      p {
        margin: .1rem 0;
        line-height: .3rem;
        font-size: .18rem;
      }
    }
    
  }
  .game {
    font-size: .18rem;
    .gameContent {
      max-width: 11.4rem;
      flex(row, nowrap, space-between, center);
      margin: 0 auto;
      padding: .5rem .4rem;
      .gameLogo {
        height: 5.75rem;  
      }
      & >div {
        max-width: 4.2rem;
      }
      .google {
        margin-top: .3rem;
        cursor: pointer;
        width: 1.6rem;
      }
        
    }  
    p {
      margin: .2rem auto 0;
      line-height: .3rem;
      color: $C0;
    }
  }
}
</style>